import React from 'react';
import Helmet from 'react-helmet';

export default React.memo(
    ({
        schemas,
        hreflang_links
    }) => {

        const schemaInit = `{
            "@context":"https://schema.org",
            "@type":"Organization",
            "name":"Super Contadores RD",
            "url":"https://www.supercontadoresrd.com/"
        `
        const schemaFull = schemas ? schemaInit.concat(',',schemas,'}') : schemaInit.concat('}')
        var hreflangs = hreflang_links ? hreflang_links : '[]'
        var newJson = hreflangs.replace(/'/g, '"');
        hreflangs = JSON.parse(newJson);

        return (
        <Helmet defer={false} link={hreflangs}>
            {/* Schema.org tags */}
            <script type="application/ld+json">{schemaFull}</script>
        </Helmet>
        );
    },
);