import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../atoms/Heading'
import Paragraph from '../atoms/Paragraph'
import Button from '../atoms/Button'
import image from '../../images/bg-accountants.png'

const ArrowSVG = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="20" height="20" rx="10" fill="#FFD300"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M7.99382 13.8433L4.62073 10.4702C4.55491 10.4044 4.464 10.3636 4.36364 10.3636C4.16255 10.3636 4 10.5265 4 10.7273C4 10.8276 4.04036 10.9185 4.10655 10.9847L7.74291 14.6207C7.80873 14.6862 7.89927 14.7273 8 14.7273C8.10364 14.7273 8.19564 14.6829 8.26182 14.6135L14.3353 8.25345C14.3353 8.25418 14.8556 7.71091 14.8556 7.71091C14.8553 7.71055 15.8993 6.61455 15.8993 6.61455C15.9604 6.548 16 6.46036 16 6.36364C16 6.16291 15.8371 6 15.6364 6C15.5324 6 15.4404 6.04436 15.3742 6.11345L14.4389 7.09164C14.4382 7.09055 13.9295 7.62036 13.9295 7.62036C13.9302 7.62145 7.99382 13.8433 7.99382 13.8433Z" fill="white" stroke="white"/>
    </svg>
}

function HeroWithCenteredContent({heading, text, cta_link, cta_text, features}) {
    return <div className="hero-centered-content" style={{backgroundImage: `url(${image})`}}>
        <div className="hero-centered-content__wrapper">
            <Heading className="hero-centered-content__heading">{ heading }</Heading>
            <Paragraph className="hero-centered-content__text">{ text }</Paragraph>
            <Button className="hero-centered-content__cta" secondary link={cta_link}>{ cta_text }</Button>
            <ul className="hero-centered-content__list">
                {features.map(feature => <li className="hero-centered-content__list-item"><ArrowSVG />{feature}</li>)}
            </ul>
        </div>
    </div>
}

HeroWithCenteredContent.propTypes = {

}

export default HeroWithCenteredContent

