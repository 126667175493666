import React from 'react'
import { Link as GatsbyLink } from "gatsby"
import { isExternalLink } from '../../library/utils'
import { resolveFormByName } from '../../config/hubspot-forms'
import HubspotModalLauncher from '../../library/HubspotModalLauncher/HubspotModalLauncher'

const HubspotLink = ({action, clases, ...attrs}) => {
    const formName = action.replace(/^hubspot:/, '')
    const formSettings = resolveFormByName(formName)

    return <a className={clases} onClick={evt => {
        HubspotModalLauncher.open(formSettings)
    }} {...attrs}></a>
}

export default function Link({className = '', to, ...attrs}) {
    const clases = `text-primary hover:text-secondary cursor-pointer hover:underline ${className ? ` ${className}`:''}`
    if(to && typeof to === 'function'){
        return <a target="_blank" className={clases} {...attrs} onClick={to}></a>
    }else if(to && to.startsWith('hubspot:')){
        return <HubspotLink action={to} {...{clases, ...attrs}}></HubspotLink>
    }else if(to){
        return isExternalLink(to) ? <a href={to} target="_blank" className={clases} {...attrs}></a> : <GatsbyLink className={clases} {...attrs}></GatsbyLink>
    }
}
