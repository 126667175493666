import { graphql } from 'gatsby'
import React, { useEffect } from 'react'
import Header from '../components/organisms/Header'
import BenefitsSection from '../components/sections/BenefitsSection'
import CtaBannerSection from '../components/sections/CtaBannerSection'
import HeroWithCenteredContent from '../components/sections/HeroWithCenteredContent'
import JoinFromAppSection from '../components/sections/JoinFromAppSection'
import NewsletterSection from '../components/sections/NewsletterSection'
import MainLayout from './Layouts/MainLayout'

const HomePage = ({ data }) => {
    const { frontmatter } = data.markdownRemark
    const menuName = frontmatter.menu ? frontmatter.menu: 'main'

    return <MainLayout seo={frontmatter.seo}>
        <Header menu={menuName} fixed={frontmatter.header.fixed}></Header>
        {frontmatter.hero && <HeroWithCenteredContent {...frontmatter.hero}/>}
        {frontmatter.benefits && <BenefitsSection {...frontmatter.benefits} />}
        {frontmatter.banner && <CtaBannerSection {...frontmatter.banner}/>}
        {frontmatter.join_from_app && <JoinFromAppSection {...frontmatter.join_from_app}/>}
        {frontmatter.newsletter && <NewsletterSection {...frontmatter.newsletter}/>}
    </MainLayout>
}

export default HomePage

export const HomeQuery = graphql`
    query HomePage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                menu
                header{
                    fixed
                }
                hero{
                    heading
                    text
                    cta_link
                    cta_text
                    features
                }
                benefits{
                    heading
                    benefits
                }
                #banner{
                    #heading
                    #text
                    #cta_text
                    #cta_link
                    #note
                #}
                join_from_app{
                    heading
                    first_indication
                    second_indication
                    cta_link
                    cta_text
                    google_play_link
                    app_store_link
                }
                newsletter{
                    heading
                }
                seo{
                    title
                    description
                    keywords
                    language
                    metatags{
                        hreflang_links
                    }
                    schemas
                    lang
                }
            }
        }
    }
`

/**
 * seo {
                metatags {
                    title
                    description
                    keywords
                    language
                    hreflang_links
                }
                schemas {
                    schemas
                }
            }
 */