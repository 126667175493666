import React, { useState, useEffect, useRef } from 'react'
import Image from '../atoms/Image'
import Heading from '../atoms/Heading'
import Link from '../atoms/Link'
import menus from '../../config/menus'
import { Button } from '..'
import { LOGO } from '../../config/general'
import HubspotModalLauncher from '../../library/HubspotModalLauncher/HubspotModalLauncher'
import { HS_CONTACT_FORM } from '../../config/hubspot-forms'

export default function Header({menu = 'main', fixed = false, topLabelContent = null}) {
    const menuLinks = menus[menu]
    const [fixedClass, setFixedClass] = useState(fixed ? 'fixed top-0' : 'static')
    const [navIsOpen, setNavIsOpen] = useState(false)
    const [thereIsScroll, setThereIsScroll] = useState(false)
    const headerRef = useRef(null)

    const toggleNavOpen = () => {
        setNavIsOpen(!navIsOpen)
    }

    useEffect(() => {
        const handleScroll = (evt) => {
            if(typeof window !== 'undefined' && window.scrollY !== 0){
                headerRef.current.classList.add('bg-tertiary')
            }else if(window.scrollY === 0){
                headerRef.current.classList.remove('bg-tertiary')
            }
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return <div ref={headerRef} className={`w-full z-10 ${fixedClass}`}>
        { topLabelContent && <div className="px-6 sm:px-10">
            <div className="text-center sm:text-right py-3 border-b text-xs sm:text-base" dangerouslySetInnerHTML={{__html: topLabelContent}}></div>
        </div>}
        <header className={`py-3 px-6 md:px-20 flex justify-between bg-transparent`}>
            {/* Button para activar el nav móvil */}
            <button className="sm:hidden rounded-lg md:hidden focus:outline-none focus:shadow-outline" onClick={toggleNavOpen}>
                <svg fill="white" viewBox="0 0 20 20" className="w-6 h-6">
                    {!navIsOpen && <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z" clipRule="evenodd"></path>}
                    {navIsOpen && <path x-show="open" fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path>}
                </svg>
            </button>
            
            <div className="flex justify-between content-center">
                <Image src={LOGO} className="w-44"/>
            </div>
            
            {/**
             * NAV Para dispositivos arriba de la medida sm
             */}
            <nav className="hidden sm:block">
                { menuLinks.map(({type, label, to}, index) => <Link key={index} className="ml-9" to={to}>{ label }</Link>) }
                <Button secondary className="ml-9" link="https://t.me/joinchat/DHV3c9oj4bVhM2Mx">ÚNETE GRATIS</Button>
            </nav>
        </header>

        {/**
         * NAV Para dispositivos móviles
         */}
        <nav className={`${navIsOpen ? 'sm:hidden' : 'hidden'} w-full flex flex-col pt-3 pb-5 shadow-lg transition`}>
            { menuLinks.map(({type, label, to}, index) => <Link key={index} className="px-10 my-2" to={to}>{ label }</Link>) }
            <Button secondary small className="ml-10 w-40 mt-4" link="https://t.me/joinchat/DHV3c9oj4bVhM2Mx">ÚNETE GRATIS</Button>
        </nav>
    </div>
}