import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import favicon from '../../images/favicon.png';
import SchemaOrg from './SchemaOrg'
import { withPrefix } from 'gatsby'

function SEO({ title, description, metatags: meta, lang, schemas }) {
    const path = typeof window === "undefined"  ? '' : window.location.pathname
    const url = typeof window === "undefined"  ? '' : window.location.href

    return (
        <React.Fragment>
            <Helmet defer={false} title={title} titleTemplate={`${title}`}>
                <link href={favicon} rel="shortcut icon" type="image/png"/>

                {/* Meta tags */}
                <meta name="title" content={title} />
                <meta name="description" content={description} />
                <meta name="language" content={meta.language} />
                {/*<meta name="author" content="Soluciones Alegra" />*/}
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"/>
            
                {/* OpenGraph tags */}
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                {/*<meta property="og:image" content="https://cdn2.alegra.com/website/imagen-facebook.png" />*/}
                <meta property="og:site_name" content="Super Contadores RD" />
                {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}
            
                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary" />
                {/*<meta name="twitter:site" content="@alegraweb" />*/}
                {/*<meta name="twitter:creator" content="@alegraweb" />*/}
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                {/*<meta name="twitter:image" content="https://cdn2.alegra.com/website/imagen-facebook.png" />*/}
                
                {/* Etiquetas de idioma y ubicación */}
                <html lang={lang}/>
            </Helmet>
            {<SchemaOrg schemas={schemas} hreflang_links={meta.hreflang_links}/>}
        </React.Fragment>
    )
}

SEO.defaultProps = {
    lang: `es`,
    metatags: {},
    description: ``,
    title: '',
    keywords: '',
    language: 'Spanish',
}

SEO.propTypes = {
    lang: PropTypes.string,
    metatags: PropTypes.object,
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    keywords: PropTypes.string,
    language: PropTypes.string
}

export default SEO