import { EventEmitter } from 'events';

const Constants = {
    OPEN: 'hs_open',
    CLOSE: 'hs_close',
};

class HubspotModalLauncher extends EventEmitter {
    addOpenListener(callback) { this.addListener(Constants.OPEN, callback) }
    addCloseListener(callback) { this.addListener(Constants.CLOSE, callback) }
    
    removeOpenListener(callback) { this.removeListener(Constants.OPEN, callback) }
    removeCloseListener(callback) { this.removeListener(Constants.CLOSE, callback) }

    open(portalId, formId){
        this.emit(Constants.OPEN, portalId, formId)
    }

    close(){
        this.emit(Constants.CLOSE)
    }
}

export default new HubspotModalLauncher();