import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../atoms/Heading'
import HubspotForm from 'react-hubspot-form'

function NewsletterSection({heading}) {
    return <section className="newsletter-section section">
        <Heading className="newsletter-section__heading">{ heading }</Heading>
        <HubspotForm
            cssClass="newsletter-section__form"
            portalId='6180490'
            formId='75c31c13-0fb1-4557-9a66-bb161bb19e43'
            onSubmit={() => console.log('Submit!')}
            onReady={(form) => console.log('Form ready!')}
            loading={<div>Cargando...</div>}
            />
    </section>
}

NewsletterSection.propTypes = {

}

export default NewsletterSection

