export default {
    main: [
        /*{
            type: 'link',
            label: 'Inicio',
            to: () => {
                if(typeof window != undefined){
                    window.scroll({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            }
        },
        {
            type: 'link',
            label: 'Contacto',
            to: 'hubspot:contact_form'
        }*/
    ]
}