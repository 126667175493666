import React from 'react'
import { Link } from '..'
import { resolveFormByName } from '../../config/hubspot-forms'
import HubspotModalLauncher from '../../library/HubspotModalLauncher/HubspotModalLauncher'

const HubspotButton = ({action, ...attrs}) => {
    const formName = action.replace(/^hubspot:/, '')
    const formSettings = resolveFormByName(formName)

    return <Button onClick={evt => {
        HubspotModalLauncher.open(formSettings)
    }} {...attrs}></Button>
}

export default function Button({primary, secondary, transparent, small, large, children, className, link, linkBlank, ...attrs}) {
    const backgroundColorClass = transparent ? 'bg-transparent' : primary ? 'bg-primary' : (secondary ? 'bg-secondary' : 'bg-gray-400')
    const borderColorClass = transparent ? (primary ? 'border border-primary' : (secondary ? 'border border-secondary' : 'border border-gray-400')) : 'border-0'
    const sizeClass = small ? 'text-sm py-2' : (large ? 'text-lg py-3' : 'text-md py-3')
    const textColorClass = transparent ? (primary ? 'text-primary' : (secondary ? 'text-secondary' : 'text-black')) : 'text-black'

    if(link && link.startsWith('hubspot:')){
        return <HubspotButton action={link} {...{primary, secondary, transparent, small, large, children, className, linkBlank, ...attrs}}></HubspotButton>
    }else if(link){
        return <Link to={link}>
            <button className={`${backgroundColorClass} ${borderColorClass} ${textColorClass} ${sizeClass} border-primary rounded-full font-semibold px-6${className ? ` ${className}` : ''}`} {...attrs}>{ children }</button>
        </Link>
    }else{
        return <button className={`${backgroundColorClass} ${borderColorClass} ${textColorClass} ${sizeClass} border-primary rounded-full font-semibold px-6${className ? ` ${className}` : ''}`} {...attrs}>{ children }</button>
    }
}
