import React from 'react'
import PropTypes from 'prop-types'
import Image from '../atoms/Image'
import Heading from '../atoms/Heading'
import Paragraph from '../atoms/Paragraph'

import googlePlay from '../../images/google-play.png'
import appStore from '../../images/app-store.png'
import Button from '../atoms/Button'

function JoinFromAppSection({heading, first_indication, second_indication, cta_link, cta_text, google_play_link, app_store_link}) {
    return <section className="join-from-app-section section">
        <Heading className="join-from-app-section__heading">{ heading }</Heading>
        <Paragraph className="join-from-app-section__indication">{ first_indication }</Paragraph>
        <div className="join-from-app-section__buttons">
            <a href={google_play_link} target="_blank">
                <Image className="join-from-app-section__image-button" src={googlePlay}/>
            </a>
            <a href={app_store_link} target="_blank">
                <Image className="join-from-app-section__image-button" src={appStore}/>
            </a>
        </div>
        <Paragraph className="join-from-app-section__indication">{ second_indication }</Paragraph>
        <Button className="join-from-app-section__cta" secondary link={cta_link}>{ cta_text }</Button>
    </section>
}

JoinFromAppSection.propTypes = {

}

export default JoinFromAppSection

