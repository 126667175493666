import React from 'react'
import HubspotModalContainer from '../../library/HubspotModalLauncher/HubspotModalContainer'
import SEO from './SEO'

export default function MainLayout({seo, children}) {
    return <React.Fragment>
        {seo && <SEO {...seo}></SEO>}
        <main>{ children }</main>
        <HubspotModalContainer/>
    </React.Fragment>
}