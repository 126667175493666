import React from 'react'
import PropTypes from 'prop-types'
import Paragraph from '../atoms/Paragraph'
import Heading from '../atoms/Heading'
import Button from '../atoms/Button'

function CtaBannerSection({heading, text, cta_text, cta_link, note}) {
    return <section className="cta-banner-section section">
        <Heading className="cta-banner-section__heading">{ heading }</Heading>
        <Paragraph className="cta-banner-section__text">{ text }</Paragraph>
        <div>
            <Button secondary className="cta-banner-section__cta" link={cta_link}>{ cta_text }</Button>
        </div>
        <Paragraph className="cta-banner-section__note">{ note }</Paragraph>
    </section>
}

CtaBannerSection.propTypes = {

}

export default CtaBannerSection

