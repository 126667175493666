import React from 'react'
import PropTypes from 'prop-types'
import Heading from '../atoms/Heading'
import Image from '../atoms/Image'
import img from '../../images/phone-benefits.png'

function BenefitsSection({heading, benefits}) {
    return <section className="benefits-section section">
        <div className="benefits-section__image-wrapper">
            <Image src={img} className="benefits-section__image"/>
        </div>
        <div className="benefits-section__content">
            <Heading className="benefits-section__heading">{ heading }</Heading>
            <ul className="benefits-section__list">
                {benefits.map(benefit => <li className="benefits-section__list-item">{ benefit }</li>)}
            </ul>
        </div>
    </section>
}

BenefitsSection.propTypes = {

}

export default BenefitsSection

